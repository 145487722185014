.Home {
  font-size: 1.5rem;
  /* height: 100vh;
  width: 100vw; */
}

.chord-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 10px solid rgb(12, 164, 210);
}

.chords Button {
  /* background-color: rgb(12, 164, 210); */
  margin: 10px;
  width: 50px;
  height: 10px;
}
.A.chord-button {
  /* background-color: red; */
}

.string-chords {
  /* display: flex; */
  width: 35px;
  height: 1px;
  margin-bottom: 2px;
}

.string-1-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-1-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.string-2-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-2-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.string-3-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-3-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.string-4-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-4-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.string-5-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-5-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.string-6-chords Button {
  margin-left: 60px;
  border-radius: 100px;
}

.string-6-chords {
  display: flex;
  justify-content: center;
  align-items: center;
}

.frets-1-6 {
  display: flex;
  margin-left: 140px;
  border: solid;
}
.frets-1-6 h5 {
  border: solid;
  padding-left: 35px;
  padding-right: 35px;
  /* margin-bottom: 50px; */
}

.frets-7-12 {
  display: flex;
  margin-right: 140px;
  border: solid;
}
.frets-7-12 h5 {
  border: solid;
  padding-left: 28px;
  padding-right: 28px;
}

.string-1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border: solid;
}
.string-2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.string-3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.string-4 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.string-5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.string-6 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.main-fretboard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 300px;
  width: 1300px;
  background-image: url("../../assets/fretboard-1-12.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  /* padding: 20px; */
  /* border: solid red; */
}

.scales-container {
  display: flex;
  flex-direction: column;
  max-height: 40%;
  /* max-width: 40vw; */
  overflow-y: scroll;
  border: 1px solid black;
  background-color: white;
  margin: 20px;
  padding: 1rem;
}

.nav-bar {
  display: flex;
  justify-content: flex-end;
  /* margin: 1rem; */
  background-color: rgb(100, 147, 216);
}

.nav-bar div {
  margin: 1rem;
}
