.Song-Library {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.nav-bar {
  display: flex;
  justify-content: flex-end;
  background-color: rgb(100, 147, 216);
}

.nav-bar div {
  margin: 1rem;
}

.song-card {
  margin: 1rem;
  margin-left: 200px;
  margin-right: 200px;
  /* padding: 10rem; */
  /* max-width: 40%; */
}

.songs {
  border: solid;
  /* padding: 10px;
  margin: 10px; */
}
