.login_page {
    background-image: url("../../assets/guitar-background.jpeg");
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.login_ui {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 1000px;
    width: 1000px; */
}